import { Alert, AlertTitle, Button, Grid } from "@mui/material";
import { get, isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { WrapperContainer } from "../../../components/common";
import SingleTickets from "../../../components/SingleTickets/SIngleTickets";

const PaymentInfoPage = ({ paymentInfo }) => {
  return (
    <WrapperContainer>
      <Alert
        severity={paymentInfo?.transactionSuccessful ? "success" : "error"}
      >
        <AlertTitle>{paymentInfo?.userText}</AlertTitle>
        {paymentInfo?.transactionText && paymentInfo?.transactionText}
      </Alert>
      <Grid
        container
        spacing={2}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent="center"
        alignItems="center"
        mt={2}
      >
        {!isEmpty(paymentInfo) &&
          paymentInfo?.transactionSuccessful &&
          paymentInfo?.bookingDetailsArray.map((item, index) => {
            return (
              <Grid item key={index}>
                <SingleTickets {...item} />
              </Grid>
            );
          })}
      </Grid>
      <Link to={"/home"} mt={1}>
        <Button>Go to Home page</Button>
      </Link>
    </WrapperContainer>
  );
};
const mapStateToProps = (state) => ({
  paymentInfo: get(state, "payment.paymentInfo", null),
});
export default connect(mapStateToProps)(PaymentInfoPage);
