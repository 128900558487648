import { Button, FormLabel, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import CheckboxWrapper from "../../common/CheckboxWrapper";
import RadioButtonWrapper from "../../common/RadioButtonWrapper";
import { addEvent } from "../../../redux/actions/eventAction";
import { get, isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

const INITIAL_FORM_STATE = {
  CancellationorRefundPolicy: false,
  Covid19Policy: false,
  showQr: false,
};

const FORM_VALIDATION = Yup.object().shape({});

const Policies = ({ updateState, state, addEvent, isEventAddSuccess }) => {
  const history = useNavigate();
  React.useEffect(() => {
    console.log("State in policy", state);
    if (state && !isEmpty(state?.policies)) {
      addEvent(state);
      history("/event");
    }
  }, [state]);
  return (
    <Formik
      initialValues={{
        ...INITIAL_FORM_STATE,
      }}
      validationSchema={FORM_VALIDATION}
      onSubmit={(values, { resetForm }) => {
        console.log("values-> event =", values);
        const temp = [];
        Object.keys(values).forEach((key, index) => {
          if (values[key] && key !== "showQr") {
            temp.push(key);
          }
        });
        console.log("temp", temp);
        updateState({
          policies: temp,
          showQr: values.showQr,
        });
      }}
    >
      <Form>
        <FormLabel
          id="demo-controlled-radio-buttons-group"
          className="text-body2-bold"
        >
          Policies
        </FormLabel>
        <Grid>
          <Grid pt={1}>
            <CheckboxWrapper
              name="CancellationorRefundPolicy"
              legend="CancellationorRefundPolicy"
              label={
                <Typography className="text-body2-bold text-blue-500">
                  Cancellation or Refund Policy
                </Typography>
              }
              desc={
                <Typography className="text-body1-bold text-grey">
                  The settlement will be done after completion of the even, One
                  of our executives will get in touch with you to transfer the
                  amount.
                </Typography>
              }
            />
          </Grid>
          <Grid pt={2}>
            <CheckboxWrapper
              name="Covid19Policy"
              legend="Covid-19 Policy"
              label={
                <Typography className="text-body2-bold text-blue-500">
                  Covid-19 Policy
                </Typography>
              }
              desc={
                <Typography className="text-body1-bold text-grey">
                  All the guests are mandatory to follow the expected COVID-19
                  norms and COVID appropriate behaviour Wearing mask is
                  compulsory for event admissionand should wear them in the
                  premises.
                </Typography>
              }
            />
          </Grid>
          <Grid pt={2} pb={1}>
            <CheckboxWrapper
              name="showQr"
              legend="ShowQR"
              label={
                <Typography className="text-body2-bold text-blue-500">
                  ShowQR
                </Typography>
              }
            />
          </Grid>
        </Grid>
        <Grid container xs={12} justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            className="submit-button m-5"
          >
            <Typography className="text-body1-bold">Create</Typography>
          </Button>
        </Grid>
      </Form>
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  isEventAddSuccess: get(state, "event.isEventAddSuccess", false),
});

export default connect(mapStateToProps, { addEvent })(Policies);
