import React from 'react'
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { get, isEmpty } from 'lodash'
import { connect } from 'react-redux'

const UserTable = ({userTableData}) => {
  return (
    !isEmpty(userTableData) && (
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Phone Number</TableCell>
          <TableCell align="right">Email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {userTableData.map((item,index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {item?.userDetails?.name}
            </TableCell>
            <TableCell align="right">{item?.userDetails?.mobile}</TableCell>
            <TableCell align="right">{item?.userDetails?.email}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>)
  )
}
const mapStateToProps = (state) => ({
    userTableData:get(state,"event.data",[])
  });
export default connect(mapStateToProps) (UserTable)