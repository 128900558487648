import {
  CLEAR_ERRORS,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  LOGOUT,
  UPDATE_ORGANIZER_PROFILE_REQUEST,
  UPDATE_ORGANIZER_PROFILE_SUCCESS,
  UPDATE_ORGANIZER_PROFILE_FAIL,
} from '../constants'
import jwtDecode from 'jwt-decode'
import { isEmpty } from 'lodash'
const initState = {
  loading: false,
  user: '',
  otpId: null,
  otpSuccess: false,
  isAuthenticated: false,
  error: {},
}
const verifyToken = (user) => {
  const decodeToken = jwtDecode(user?.accessToken)
  const expiresIn = new Date(decodeToken.exp * 1000)
  if (new Date() > expiresIn) {
    localStorage.removeItem('user')
    initState.user = null
    return null
  } else {
    return user
  }
}
const userInfo = localStorage.getItem('user')
if (!isEmpty(userInfo)) {
  const decoded = verifyToken(JSON.parse(userInfo))
  if (decoded) {
    initState.user = decoded
  }
}
// Auth reducer
export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case SEND_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      }

    case SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otpId: action.payload,
        otpSuccess: true,
        error: {},
      }
    case SEND_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error:  action.payload,
        otpSuccess: false,
      }
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      }

    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        user: verifyToken(action.payload),
        error: {},
      }

    case VERIFY_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      }
      
      case UPDATE_ORGANIZER_PROFILE_REQUEST:
        return {
          ...state,
          loading: true,
          error: {},
        }
  
      case UPDATE_ORGANIZER_PROFILE_SUCCESS:
        const newUserInfo={
          ...state.user,
          name:action.payload?.name,
          email:action.payload?.email,
          hqLocation:action.payload?.hqLocation,
          description:action.payload?.description,
          isActive:action.payload?.isActive,
          profileComplete:action.payload?.profileComplete,
        }
        localStorage.setItem('user',JSON.stringify(newUserInfo)) 
        return {
          ...state,
          loading: false,
          user:newUserInfo,
        }
      case UPDATE_ORGANIZER_PROFILE_FAIL:
        return {
          ...state,
          loading: false,
          error:  action.payload,
        }  
    case LOGOUT:
      localStorage.removeItem('user')
      return {
        loading: false,
        user: '',
        otpId: null,
        otpSuccess: false,
        isAuthenticated: false,
        error: {},
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: {},
      }
    default:
      return state
  }
}
