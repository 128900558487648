import { isEmpty } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const GuestRoute = () => {
  const { user } = useSelector((state) => state.auth);
  if (!isEmpty(user) && user.accessToken) {
    return <Navigate to="/event" />;
  }
  return <Outlet />;
};

export default GuestRoute;
