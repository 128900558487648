import React from "react";
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

const CheckboxWrapper = ({ name, label, legend, desc, ...otherProps }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const handleChange = (evt) => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
  };

  const configCheckbox = {
    ...field,
    onChange: handleChange,
  };

  const configFormControl = {};
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
  }
  return (
    <FormControl>
      <FormLabel></FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          label={label}
          {...configCheckbox}
        />
      </FormGroup>
      <Typography>{desc}</Typography>
    </FormControl>
  );
};

export default CheckboxWrapper;
