import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { useField } from 'formik';

const SelectWrapper = ({ name,
    item,
    ...otherProps}) => {
    const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return (
    <Select
    fullWidth
    {...configTextfield}
  >
     <MenuItem value="">Select Option </MenuItem>
    {item.map((item, index) => (
        <MenuItem value={item} key={index}>{item}</MenuItem>
    ))}
  </Select>
  )
}

export default SelectWrapper