import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { NODE_ENV } from '../config/endPoints'
import reducers from './reducers/redcuers'

const bindMiddlware = (middlware) => {
  if (NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middlware))
  }
  return applyMiddleware(...middlware)
}

const store = createStore(
  reducers,
  bindMiddlware([thunkMiddleware]),
)

export default store
