import React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { TextFieldWrapper } from "../../common";
import SelectWrapper from "../../common/SelectWrapper";
import { addCustomerData } from "../../../redux/actions/eventAction";
const SelectTextBox = (item, index) => {
  console.log(item);
  switch (item.answerType) {
    case "DROP_DOWN":
      return (
        <Grid item xs={12}>
          <SelectWrapper
            label={item?.questionText}
            placeholder={item?.questionText}
            item={item?.answerOptions}
            key={index}
            name={`answer.${index}.select`}
            required={item?.mandatory}
          />
        </Grid>
      );
    case "DONATION":
      return (
        <Grid item xs={12}>
          <TextFieldWrapper
            label={item.questionText}
            placeholder={item?.questionText}
            item={item?.answerOptions}
            name={`answer.${index}.select`}
            key={index}
            type="number"
            min={0}
            required={item?.mandatory}
          />
        </Grid>
      );
    default:
      break;
  }
};
const QuestionsFrom = ({
  handleClose,
  eventInfo,
  updateState,
  state,
  addCustomerData,
}) => {
  const INITIAL_FORM_STATE = {
    answer: [],
  };
  const FORM_VALIDATION = Yup.object().shape({
    answer: Yup.array().of(
      Yup.object({
        select: Yup.string().typeError("Please enter a answer"),
      })
    ),
  });
  React.useEffect(() => {
    if (state && !isEmpty(state?.extraQuestions)) {
      addCustomerData(state);
      handleClose();
    }
  }, [state]);

  return (
    <>
      <DialogTitle>Questions From</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={async (values, { resetForm }) => {
            const newData = eventInfo?.extraQuestions.map((item, index) => ({
              questionCode: item.questionCode,
              questionText: item.questionText,
              answerType: item.answerType,
              answer: values?.answer[index]?.select,
            }));
            if (newData) {
              updateState({ extraQuestions: newData });
            }
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} pt={1}>
                  <FieldArray name="answer">
                    <React.Fragment>
                      {eventInfo?.extraQuestions?.map((item, index) =>
                        SelectTextBox(item, index)
                      )}
                    </React.Fragment>
                  </FieldArray>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" type="submit">
                  Done
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  );
};
const mapStateToProps = (state) => ({
  eventInfo: get(state, "event.eventInfo", {}),
});

export default connect(mapStateToProps, { addCustomerData })(QuestionsFrom);
