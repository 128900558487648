import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Chip,
  Grid,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { get, isEmpty } from "lodash";
import React from "react";
import { format } from "date-fns";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  addEventId,
  getInfoOrganizerEvent,
  getViewMoreOrganizerEvent,
} from "../../../redux/actions/eventAction";

const OrganizerEventInfo = ({
  organizerEventInfo,
  getInfoOrganizerEvent,
  addEventId,
  getViewMoreOrganizerEvent,
}) => {
  const history = useNavigate();
  const [skip, setskip] = React.useState(0);
  React.useEffect(() => {
    if (skip !== 0) {
      getInfoOrganizerEvent(skip, 10);
    }
    // eslint-disable-next-line
  }, [skip]);

  const paginationleft = async () => {
    if (skip >= 0) {
      setskip(skip - 10);
      return;
    }
  };
  const paginationright = async () => {
    setskip(skip + 10);
    console.log("here");
  };

  const handleEventDialog = async (eventId) => {
    await addEventId(eventId);
    await getViewMoreOrganizerEvent();
    history("/event-info");
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5" pb={2} className="text-head-bold">
            Your Events
          </Typography>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            onClick={() => {
              history("/add-event");
            }}
          >
            <Typography className="text-body1-normal" p={0.5}>
              Add Event
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ overflow: "auto" }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          {!isEmpty(organizerEventInfo) && (
            <>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {organizerEventInfo.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="text-body1-bold text-blue-500"
                        >
                          {item?.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          className="text-body1-bold text-blue-500"
                        >
                          {item?.eventAddress}
                        </TableCell>
                        <TableCell
                          align="right"
                          className="text-body1-bold text-blue-500"
                        >
                          {item?.eventCode}
                        </TableCell>
                        <TableCell
                          align="right"
                          className="text-body1-bold text-blue-500"
                        >
                          {item.startDate &&
                            format(new Date(item.startDate), "do MMM")}
                          -
                          {item.startDate &&
                            format(new Date(item.endDate), "do MMM")}
                        </TableCell>
                        <TableCell align="right">
                          {item.isActive ? (
                            <Chip label="Active" color="success" />
                          ) : (
                            <Chip label="Inactive" color="error" />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            color="primary"
                            onClick={() => handleEventDialog(item._id)}
                          >
                            View More{" "}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "20px",
            }}
          >
            <IconButton className="arrow-button" onClick={paginationleft}>
              <ChevronLeftIcon />
            </IconButton>

            <IconButton className="arrow-button" onClick={paginationright}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  organizerEventInfo: get(state, "event.organizerEventInfo", {}),
});

export default connect(mapStateToProps, {
  getInfoOrganizerEvent,
  addEventId,
  getViewMoreOrganizerEvent,
})(OrganizerEventInfo);
