import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { images } from "../../../assets";
import { logOut } from "../../../redux/actions/userActions";

const Active = ({ logOut }) => {
  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper elevation={0} className="p-15">
          <Grid container justifyContent="center" alignItems="center" pb={2}>
            <img src={images.logo} />
          </Grid>
          <Grid container justifyContent="center" alignItems="center" p={1.5}>
            <Typography paddingY={2} className="text-h5-bold">
              Your profile is not active
            </Typography>
            <Button
              size="large"
              fullWidth
              variant="contained"
              className="button-primary"
              onClick={logOut()}
            >
              <Typography className="text-body1-bold">Login</Typography>
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
};

export default connect(null, { logOut })(Active);
