import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { images } from "../../assets/index";
import { useNavigate } from "react-router-dom";

const NoEventsFound = () => {
  const history = useNavigate();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "70vh" }}
    >
      <Grid container justifyContent="center" alignItems="center" pb={2}>
        <img src={images.error} alt="error" width="80rem" />
      </Grid>
      <Typography className="text-body2-bold">Oops!</Typography>
      <Typography className="text-body1-bold text-grey" mb={2}>
        No Event Found
      </Typography>
      <Button
        variant="contained"
        onClick={() => history("/event")}
        className="text-body1-bold p-10"
      >
        Go to Event Page
      </Button>
    </Grid>
  );
};

export default NoEventsFound;
