import { Container, Grid, Paper, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { images } from "../../../assets";
import { connect } from "react-redux";
import { get } from "lodash";
import { verfiyOtp } from "../../../redux/actions/userActions";
import { ButtonWrapper, TextFieldWrapper } from "../../common";
const INITIAL_FORM_STATE = {
  otp: "",
};
const FORM_VALIDATION = Yup.object().shape({
  otp: Yup.number()
    .integer()
    .typeError("Please enter a valid OTP")
    .required("Required OTP"),
});
const Otp = ({ error, loading, otpId, verfiyOtp, onNext }) => {
  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper elevation={0} className="p-15">
          <Grid container justifyContent="center" alignItems="center" pb={2}>
            <img src={images.logo} />
          </Grid>
          <Typography variant="h5" pb={2} className="text-h5-bold">
            Please fill the details to get yourself verified.
          </Typography>
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values, { resetForm }) => {
              const userdata = { otp: values.otp, otpId: otpId };
              await verfiyOtp(userdata);
              resetForm({ values: "" });
            }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextFieldWrapper
                  label="OTP"
                  placeholder="Enter your OTP"
                  variant="outlined"
                  name="otp"
                  type="text"
                />
              </Grid>
              <Grid item>
                <ButtonWrapper
                  size="large"
                  variant="contained"
                  className="button-primary"
                  disabled={loading}
                >
                  <Typography className="text-body1-bold">
                    {loading ? "..." : "Sign In"}
                  </Typography>
                </ButtonWrapper>
              </Grid>
            </Grid>
          </Formik>

          {error && error?.code === "VERFIY_OTP_ERROR" && (
            <Typography variant="subtitle1" component="p">
              {error?.message}
            </Typography>
          )}
        </Paper>
      </Grid>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  otpId: get(state, "auth.otpId", null),
  error: get(state, "auth.error", {}),
  loading: get(state, "auth.loading", false),
});

export default connect(mapStateToProps, { verfiyOtp })(Otp);
