import React from "react";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import {
  Box,
  Drawer,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { useNavigate } from "react-router-dom";
import { NavigationData } from "../../data";
import { images } from "../../assets/index";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

const DrawerWrapper = ({
  container,
  drawerWidth,
  handleDrawerToggle,
  mobileOpen,
}) => {
  const history = useNavigate();
  const drawer = (
    <div>
      <Toolbar />
      <Grid container justifyContent="center" alignItems="center" pb={2}>
        <img src={images.logo} alt="fydo" />
      </Grid>
      <List className="list">
        {NavigationData.map((item, index) => (
          <Grid container justifyContent="center">
            <ListItem key={index} className="bg-blue listItem">
              <ListItemButton
                onClick={() => history(item.path)}
                className="ListItemButton"
              >
                <ListItemIcon>
                  <CalendarTodayOutlinedIcon className="text-blue-300" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className="text-body3-bold text-blue-300">
                      {item.name}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </Grid>
        ))}
      </List>
    </div>
  );
  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderRight: "none",
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderRight: "none",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default DrawerWrapper;
