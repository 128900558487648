import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import { connect } from "react-redux";
import { logOut } from "../../redux/actions/userActions";
import { Avatar, Box, Grid, Menu, MenuItem, Tooltip } from "@mui/material";
import { get } from "lodash";
import moment from "moment";

const HeaderWrapper = ({ handleDrawerToggle, drawerWidth, logOut, user }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogOut = () => {
    logOut();
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        boxShadow: "none",
      }}
      color="default"
      className="white-bg"
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className="text-body2-bold">
              {user.name && `Hello ${user.name}`}
            </Typography>
            <Typography mt={0.5} className="text-blue-500 text-sm-bold">
              {moment().format("Do MMM YYYY")}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={user.name}
                    src="../../assets/images/avatar.jpg"
                    sx={{ bgcolor: "green" }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Typography
                  textAlign="center"
                  className="text-body1-bold"
                  mt={1}
                >
                  Profile
                </Typography>
                <Grid container justifyContent="center">
                  <Grid item p={3}>
                    <Avatar
                      alt={user.name}
                      src="../../assets/images/avatar.jpg"
                      sx={{ bgcolor: "green" }}
                    />
                  </Grid>
                </Grid>
                <Typography textAlign="center">{user.name}</Typography>
                <MenuItem onClick={handleLogOut} sx={{ width: "13rem" }}>
                  <Typography
                    textAlign="center"
                    className="text-red text-sm-bold"
                  >
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
const mapStateToProps = (state) => ({
  user: get(state, "auth.user", {}),
});
export default connect(mapStateToProps, { logOut })(HeaderWrapper);
