import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import { images } from "../../assets";
import { Person, Email, LocalPhone } from "@mui/icons-material";

const SingleTickets = ({
  bookingCode,
  numberOfSeats,
  userDetails,
  ticketTypes,
}) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        alt="green iguana"
        width="100%"
        sx={{ padding: "1rem", objectFit: "contain" }}
        image={images.greetFydo}
      />
      <CardContent>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          py={1}
        >
          <Grid item>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              textAlign="center"
              className="text-head-bold"
            >
              {bookingCode && bookingCode}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              textAlign="center"
              className="text-head-bold"
            >
              {numberOfSeats && numberOfSeats}
            </Typography>
          </Grid>
        </Grid>
        {userDetails?.name && (
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Person />
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                color="text.secondary"
                className="text-body1-bold"
              >
                {userDetails?.name}
              </Typography>
            </Grid>
          </Grid>
        )}
        {userDetails?.mobile && (
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <LocalPhone />
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                color="text.secondary"
                className="text-body1-bold"
              >
                {userDetails?.mobile}
              </Typography>
            </Grid>
          </Grid>
        )}
        {userDetails?.email && (
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Email />
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                color="text.secondary"
                className="text-body1-bold"
              >
                {userDetails?.email}
              </Typography>
            </Grid>
          </Grid>
        )}
        {ticketTypes &&
          ticketTypes?.map((item) => (
            <Grid container p={1.5}>
              <Grid item xs={6} direction="column">
                <Typography className="text-body1-bold" mb={0.5}>
                  {item?.name}
                </Typography>
                <Typography className="text-body1-normal">
                  ₹ {item?.price}
                </Typography>
              </Grid>
              <Grid item xs={6} direction="column" textAlign="right">
                <Typography className="text-body1-normal">
                  {item?.quantity}
                </Typography>
              </Grid>
            </Grid>
          ))}
      </CardContent>
    </Card>
  );
};

export default SingleTickets;
