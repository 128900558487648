import { combineReducers } from 'redux'
import { eventReducer } from './eventReducers'
import { paymentReducer } from './paymentReducers'
import { authReducer } from './userReducers'

const reducer = combineReducers({
  auth: authReducer,
  event: eventReducer,
  payment: paymentReducer
})

export default reducer
