import React, { useState } from "react";
import { Dialog } from "@mui/material";
import AddUserFrom from "./addUserFrom";
import BookTicket from "./bookTicket";
import QuestionsFrom from "./QuestionsFrom";
const steps = {
  1: AddUserFrom,
  2: BookTicket,
  3: QuestionsFrom,
};
const AddTicketDialog = ({ open, handleClose }) => {
  const [step, setStep] = useState(1);
  const Step = steps[step];
  const initial_state = {
    userDetails:{},
    ticketTypeArray: [],
    extraQuestions: [],
    originalAmount: 0,
    paymentAmount: 0,
    convenienceAmount: 0,
    taxAmount: 0,
    selfPart: 0,
    walletPart: 0,
    numberOfSeats:0,
    eventId: "",
    eventName: "",
  };
  const [state, setState] = useState({ ...initial_state });
  const updateState = (data) => setState((state) => ({ ...state, ...data }));

  const onNext = () => {
    setStep(step + 1);
  };
  const handleOnClose = () => {
    setStep(1);
    updateState({ ...initial_state });
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      maxWidth="lg"
      sx={{
        "& .css-1fu2e3p-MuiPaper-root-MuiDialog-paper ": {
          borderRadius: "20px",
        },
      }}
    >
      <Step
        onNext={onNext}
        updateState={updateState}
        state={state}
        handleClose={handleOnClose}
      />
    </Dialog>
  );
};

export default AddTicketDialog;
