import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { images } from "../../../assets";
import { ButtonWrapper, TextFieldWrapper } from "../../common";
import { connect } from "react-redux";
import { updateOrganizerProfile } from "../../../redux/actions/userActions";
import { get } from "lodash";
const INITIAL_FORM_STATE = {
  name: "",
  email: "",
  hqLocation: "",
  description: "",
};
const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  hqLocation: Yup.string().required("HQ Location is required"),
  description: Yup.string().required("Description is required"),
  email: Yup.string()
    .test("email", "Invalid email", (email) => {
      const tester =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!email) return false;
      if (!tester.test(email.trim().toLowerCase())) return false;
      return true;
    })
    .max(45, "Invalid Email")
    .required("Please enter your Email"),
});
const Register = ({ loading, error, updateOrganizerProfile }) => {
  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper elevation={0} className="p-15">
          <Grid container justifyContent="center" alignItems="center" pb={2}>
            <img src={images.logo} />
          </Grid>
          <Typography variant="h5" pb={2}>
            Please fill the details to get yourself verified.
          </Typography>
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values, { resetForm }) => {
              await updateOrganizerProfile(values);
              resetForm({ values: "" });
            }}
          >
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <TextFieldWrapper
                  label="Your Name"
                  placeholder="Enter your name"
                  variant="outlined"
                  name="name"
                  type="text"
                />
              </Grid>
              <Grid item>
                <TextFieldWrapper
                  label="Email"
                  placeholder="Enter your email"
                  variant="outlined"
                  name="email"
                  type="email"
                />
              </Grid>
              <Grid item>
                <TextFieldWrapper
                  label="Headquarters Location"
                  placeholder="Enter your headquarters location"
                  variant="outlined"
                  name="hqLocation"
                  type="text"
                />
              </Grid>
              <Grid item>
                <TextFieldWrapper
                  label="Short Description"
                  placeholder="Enter your short description"
                  variant="outlined"
                  name="description"
                  type="text"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item>
                <ButtonWrapper
                  size="large"
                  variant="contained"
                  className="button-primary"
                  color="error"
                  disabled={loading}
                >
                  <Typography className="text-body1-bold">
                    {loading ? "..." : "Submit"}
                  </Typography>
                </ButtonWrapper>
              </Grid>
            </Grid>
          </Formik>
          {error && error?.code === "UPDATE_ORGANIZER_PROFILE_FAIL" && (
            <Typography variant="subtitle1" color="error" component="p">
              {error?.message}
            </Typography>
          )}
        </Paper>
      </Grid>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  error: get(state, "auth.error", {}),
  loading: get(state, "auth.loading", false),
});
export default connect(mapStateToProps, { updateOrganizerProfile })(Register);
