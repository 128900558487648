import React from "react";
import "./FileUpload.scss";
import axios from "axios";
import { Button, Grid, Typography } from "@mui/material";
import instance from "../../../../utils/instance";
import { GET_IMAGE_PRESIGNED_URL_API } from "../../../../config/endPoints";
import { AddOutlined } from "@mui/icons-material";

const FileUpload = ({ files, setFiles, images, setImages }) => {
  const [Posters, setPosters] = React.useState([]);
  const uploadHandler = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    file.isUploading = true;
    setFiles([...files, file]);
    // upload file
    const imageData = {
      fileNames: [`${new Date().valueOf()}_${file.name}`],
      folderType: "EVENTS",
    };
    try {
      const data = await instance({
        method: "POST",
        url: GET_IMAGE_PRESIGNED_URL_API,
        data: imageData,
      });
      if (data.data) {
        await axios
          .put(data.data[0], file)
          .then((response) => {
            if (response.status === 200) {
              file.isUploading = false;
              setFiles([...files, file]);
              Posters.push({ url: data.data[0].split("?")[0] });
              console.log("posters", Posters);
            }
            setImages(Posters);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid className="file-card">
        <Grid className="file-inputs">
          <input type="file" onChange={uploadHandler} />
          <Button>
            <i>
              <AddOutlined />
            </i>
            Upload
          </Button>
        </Grid>

        <Typography className="main">Supported files</Typography>
        <Typography className="info">JPG, PNG</Typography>
      </Grid>
    </>
  );
};

export default FileUpload;
