import React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import { connect } from "react-redux";
import { getInfoEvent } from "../../../redux/actions/eventAction";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextFieldWrapper } from "../../common";
import { EVENT_CODE } from "../../../config/endPoints";
import { get } from "lodash";

const INITIAL_FORM_STATE = {
  mobile: "",
  name: "",
  email: "",
};

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .test("email", "Invalid email", (email) => {
      const tester =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!email) return false;
      if (!tester.test(email.trim().toLowerCase())) return false;
      return true;
    })
    .max(45, "Invalid Email")
    .required("Please enter your Email"),
  mobile: Yup.number()
    .integer()
    .typeError("Please enter a valid mobile number")
    .required("Required mobile number"),
});
const AddUserFrom = ({
  handleClose,
  onNext,
  getInfoEvent,
  updateState,
  organizerEventViewMore,
}) => {
  console.log("data in add user form", organizerEventViewMore);
  return (
    <>
      <DialogTitle>Add User Information</DialogTitle>
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={async (values, { resetForm }) => {
          updateState({
            userDetails: {
              name: values?.name,
              email: values?.email,
              mobile: values?.mobile,
            },
          });
          await getInfoEvent(organizerEventViewMore?.eventDoc?.eventCode);
          onNext();
          resetForm({ values: "" });
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextFieldWrapper
                    type="text"
                    name="name"
                    label="Name"
                    variant="outlined"
                    placeholder="Enter your name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldWrapper
                    type="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    placeholder="Enter your email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldWrapper
                    label="Phone Number"
                    placeholder="Enter your phone number"
                    variant="outlined"
                    name="mobile"
                    type="text"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" variant="contained">
                Next
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};
const mapStateToProps = (state) => ({
  organizerEventViewMore: get(state, "event.organizerEventViewMore", []),
});
export default connect(mapStateToProps, { getInfoEvent })(AddUserFrom);
