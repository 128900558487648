import { List } from "@mui/material";
import React from "react";
import FileItem from "./../FileItem/FileItem";

const FileList = ({ files, removeFile }) => {
  const deleteFileHandler = (name) => {
    removeFile(name);
  };
  return (
    <List className="file-list">
      {files &&
        files.map((f) => (
          <FileItem key={f.name} file={f} deleteFile={deleteFileHandler} />
        ))}
    </List>
  );
};

export default FileList;
