import React, { useCallback } from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { connect } from "react-redux";
import { get } from "lodash";
import { minNumber } from "../../../utils/common";
import { addEventNewItem } from "../../../redux/actions/eventAction";

const BookTicket = ({
  handleClose,
  onNext,
  eventInfo,
  addEventNewItem,
  updateState,
}) => {
  const increasePerson = useCallback(
    (item) => {
      const newQty = item.quantity + 1;
      if (newQty > minNumber(item?.maxSeats, item?.maxBookingsAllowed)) return;
      addEventNewItem({ code: item?.code, quantity: newQty });
    },
    [addEventNewItem]
  );
  const decreasePerson = useCallback(
    (item) => {
      const newQty = item.quantity - 1;
      if (newQty <= -1) return;
      addEventNewItem({ code: item?.code, quantity: newQty });
    },
    [addEventNewItem]
  );

  const handleNext = () => {
    const newEventInfo = eventInfo.ticketTypes.filter(function (el) {
      return el.quantity >= 1;
    });
    const totalPrice = newEventInfo?.reduce(
      (acc, item) => acc + item?.quantity * item?.price,
      0
    );
    const totalQty = newEventInfo?.reduce(
      (acc, item) => acc + Number(item?.quantity),
      0
    );
    updateState({
      ticketTypeArray: newEventInfo,
      originalAmount: totalPrice,
      paymentAmount: totalPrice,
      selfPart: totalPrice,
      numberOfSeats: totalQty,
      eventId: eventInfo?._id,
      eventName: eventInfo?.name,
    });
    onNext();
  };

  return (
    <>
      <DialogTitle>Book Tickets</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pt={1}>
          {eventInfo.ticketTypes.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  alignItems="center"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "10vh",
                    minWidth: "250px",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography>{item?.name}</Typography>
                    <Typography>{item?.price}</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <IconButton
                      onClick={() => {
                        decreasePerson(item);
                      }}
                      disabled={!item?.quantity === 0}
                    >
                      <Remove />
                    </IconButton>
                    <Typography paddingX={0.5}>{item?.quantity}</Typography>
                    <IconButton
                      onClick={() => {
                        increasePerson(item);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleNext}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};
const mapStateToProps = (state) => ({
  eventInfo: get(state, "event.eventInfo", {}),
});
export default connect(mapStateToProps, { addEventNewItem })(BookTicket);
