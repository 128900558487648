import React, { useState } from "react";
import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import Events from "./Events";
import ImageGallery from "./ImageGallery";
import Policies from "./Policies";
import { Stack } from "@mui/system";
const steps = {
  1: Events,
  2: ImageGallery,
  3: Policies,
};
const AddEvents = ({ open, handleClose }) => {
  const [step, setStep] = useState(1);
  const Step = steps[step];

  const onNext = () => {
    setStep(step + 1);
  };

  const initial_state = {
    name: "",
    description: "",
    organiserPhone: "",
    organiserEmail: "",
    organiserWebsite: "",
    eventType: "",
    eventMainImageUrl: "",
    organizerLogoUrl: "",
    sponsorsLogoUrl: "",
    poster: [],
    startDate: "",
    endDate: "",
    eventAddress: "",
    ticketPrice: "",
    ticketTypes: [],
    eventCode: "",
    showQr: false,
    policies: [],
  };
  const [state, setState] = useState({ ...initial_state });
  const updateState = (data) => setState((state) => ({ ...state, ...data }));

  console.log("state", state);

  return (
    <Grid>
      <Typography className="text-h4-bold" pb={2}>
        Please fill the information
      </Typography>
      <Stack direction="row" spacing={4}>
        <ToggleButtonGroup value={step} exclusive aria-label="text alignment">
          <ToggleButton
            onClick={() => setStep(1)}
            value={1}
            className="togglebutton "
            sx={{
              "&.MuiToggleButton-root.Mui-selected": {
                backgroundColor: "#3D7FC3",
                color: "#ffffff",
              },
            }}
          >
            <FormatAlignLeftIcon sx={{ marginBottom: "0.5rem" }} />
            <Typography className="text-body3-bold ">Events</Typography>
          </ToggleButton>
          <ToggleButton
            value={2}
            onClick={() => setStep(2)}
            className="togglebutton "
            sx={{
              "&.MuiToggleButton-root.Mui-selected": {
                backgroundColor: "#3D7FC3",
                color: "#ffffff",
              },
            }}
          >
            <FormatAlignCenterIcon sx={{ marginBottom: "0.5rem" }} />
            <Typography className="text-body3-bold "> Image Gallery</Typography>
          </ToggleButton>
          <ToggleButton
            value={3}
            onClick={() => setStep(3)}
            className="togglebutton"
            sx={{
              "&.MuiToggleButton-root.Mui-selected": {
                backgroundColor: "#3D7FC3",
                color: "#ffffff",
              },
            }}
          >
            <FormatAlignRightIcon sx={{ marginBottom: "0.5rem" }} />
            <Typography className="text-body3-bold">Policies</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Grid container>
        <Grid item xs={12} lg={8} md={8} sm={12}>
          <Step onNext={onNext} updateState={updateState} state={state} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddEvents;
