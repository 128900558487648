import {
  ADD_EVENT_API,
  EVENT_INFO_DETAILS_API,
  ORGANIZER_EVENT_INFO_DETAILS_API,
  ORGANIZER_EVENT_VIEW_MORE_REQUEST_API,
} from "../../config/endPoints";
import instance from "../../utils/instance";
import {
  ADD_EVENT_NEW_ITEM,
  ADD_NEW_ANSWER,
  EVENT_INFO_FAIL,
  EVENT_INFO_REQUEST,
  EVENT_INFO_SUCCESS,
  REMOVE_EVENT_NEW_ITEM,
  REMOVE_NEW_ANSWER,
  ADD_CUSTOMERS_DATA,
  REMOVE_CUSTOMERS_DATA,
  ORGANIZER_EVENT_INFO_REQUEST,
  ORGANIZER_EVENT_INFO_SUCCESS,
  ORGANIZER_EVENT_INFO_FAIL,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  ORGANIZER_EVENT_VIEW_MORE_REQUEST,
  ORGANIZER_EVENT_VIEW_MORE_SUCCESS,
  ORGANIZER_EVENT_VIEW_MORE_FAIL,
  ADD_EVENT_ID,
} from "../constants";

export const getInfoEvent = (eventCode) => async (dispatch) => {
  console.log("event get ", eventCode);
  try {
    dispatch({ type: EVENT_INFO_REQUEST });
    const data = await instance({
      method: "GET",
      url: EVENT_INFO_DETAILS_API + eventCode,
    });
    console.log("event get data ", data);
    dispatch({
      type: EVENT_INFO_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_INFO_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: "EVENT_INFO_ERROR",
      },
    });
  }
};

export const getInfoOrganizerEvent = (skip, limit) => async (dispatch) => {
  try {
    dispatch({ type: ORGANIZER_EVENT_INFO_REQUEST });
    const data = await instance({
      method: "POST",
      url: ORGANIZER_EVENT_INFO_DETAILS_API + `?skip=${skip}&limit=${limit}`,
    });
    dispatch({
      type: ORGANIZER_EVENT_INFO_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: ORGANIZER_EVENT_INFO_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: "ORGANIZER_EVENT_INFO_ERROR",
      },
    });
  }
};

export const getViewMoreOrganizerEvent = () => async (dispatch) => {
  try {
    dispatch({ type: ORGANIZER_EVENT_VIEW_MORE_REQUEST });
    const data = await instance({
      method: "GET",
      url: ORGANIZER_EVENT_VIEW_MORE_REQUEST_API,
    });
    dispatch({
      type: ORGANIZER_EVENT_VIEW_MORE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: ORGANIZER_EVENT_VIEW_MORE_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: "ORGANIZER_EVENT_VIEW_MORE_ERROR",
      },
    });
  }
};

export const addEvent = (eventData) => async (dispatch) => {
  try {
    console.log("inside 2", eventData);
    dispatch({ type: ADD_EVENT_REQUEST });
    const data = await instance({
      method: "POST",
      url: ADD_EVENT_API,
      data: eventData,
    });
    console.log("inside api", data);
    dispatch({
      type: ADD_EVENT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: ADD_EVENT_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: "ADD_EVENT_ERROR",
      },
    });
  }
};
export const addEventId = (props) => async (dispatch) => {
  dispatch({
    type: ADD_EVENT_ID,
    payload: props,
  });
};
export const addCustomerData = (props) => async (dispatch) => {
  dispatch({
    type: ADD_CUSTOMERS_DATA,
    payload: props,
  });
};

export const removeCustomerData = () => async (dispatch) => {
  dispatch({
    type: REMOVE_CUSTOMERS_DATA,
  });
};

export const addEventNewItem = (props) => async (dispatch) => {
  dispatch({
    type: ADD_EVENT_NEW_ITEM,
    payload: props,
  });
};

export const removeEventItem = () => async (dispatch) => {
  dispatch({
    type: REMOVE_EVENT_NEW_ITEM,
  });
};

export const addNewAnswer = (props) => async (dispatch) => {
  dispatch({
    type: ADD_NEW_ANSWER,
    payload: props,
  });
};

export const removeNewAnswer = () => async (dispatch) => {
  dispatch({
    type: REMOVE_NEW_ANSWER,
  });
};
