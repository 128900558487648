import React from 'react'
import { WrapperContainer } from '../../../components/common'
import { OrganizerEventViewMore } from '../../../components/event'

const OrganizerEventViewMorePage = () => {
  return (
    <WrapperContainer>
        <OrganizerEventViewMore />
    </WrapperContainer>
  )
}

export default OrganizerEventViewMorePage