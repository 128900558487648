import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
import {
  Place,
  Email,
  LocalPhone,
  Language,
  QrCode,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { NoEventsFound } from "../../common";
import SingleTickets from "../../SingleTickets/SIngleTickets";

const OrganizerEventViewMore = ({ organizerEventViewMore }) => {
  console.log("organizerEventViewMore", organizerEventViewMore);

  const [Start, setStart] = React.useState(0);
  const [Page, setPage] = React.useState(1);

  React.useEffect(() => {
    if (Page) {
      setTickets(
        organizerEventViewMore?.ticketBookingDetails?.bookingArrayDetails.slice(
          Start,
          Start + 10
        )
      );
    }
  }, [Page]);

  const totalPages = Math.ceil(
    organizerEventViewMore?.ticketBookingDetails?.bookingArrayDetails.length /
      10
  );
  const [Tickets, setTickets] = React.useState(
    organizerEventViewMore?.ticketBookingDetails?.bookingArrayDetails.slice(
      0,
      10
    )
  );

  console.log(
    "start",
    Start,
    "Page",
    Page,
    "Total Page",
    totalPages,
    "Tickets",
    Tickets
  );

  const paginationLeft = () => {
    if (!Start <= 0) {
      setStart(Start - 10);
      setPage(Page - 1);
    }
  };
  const paginationRight = () => {
    if (Page < totalPages) {
      setStart(Start + 10);
      setPage(Page + 1);
    }
  };
  const history = useNavigate();
  const colors = ["#c0fae788", "#fff5be88", "#a9ceff88", "#cfc4ee", "#dcf3be"];
  return !isEmpty(organizerEventViewMore) ? (
    <Grid container p={2}>
      <Grid item xs={12} lg={8} md={8} sm={12}>
        <Grid container spacing={2} mt={0.5}>
          {/* <Grid item sx={6} direction="column"> */}
          <SimpleImageSlider
            width={300}
            height={300}
            images={organizerEventViewMore?.eventDoc?.poster.map((item) => {
              const url = item.url;
              return { url };
            })}
            showBullets={true}
            showNavs={true}
            style={{ position: "relative" }}
          />
          {/* </Grid> */}
          <Grid item sx={6} direction="column">
            <Typography className="text-body1-bold" p={1}>
              {organizerEventViewMore?.eventDoc?.name}
            </Typography>
            <Typography className="text-body1-normal" p={1}>
              {organizerEventViewMore?.eventDoc?.eventCode}
            </Typography>
            {organizerEventViewMore?.eventDoc?.eventAddress && (
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Place />
                </Grid>
                <Grid item>
                  <Typography className="text-body1-normal" p={1}>
                    {organizerEventViewMore?.eventDoc?.eventAddress}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {organizerEventViewMore?.eventDoc?.organiserEmail && (
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Email />
                </Grid>
                <Grid item>
                  <Typography className="text-body1-normal" p={1}>
                    {organizerEventViewMore?.eventDoc?.organiserEmail}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {organizerEventViewMore?.eventDoc?.organiserPhone && (
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <LocalPhone />
                </Grid>
                <Grid item>
                  <Typography className="text-body1-normal" p={1}>
                    {organizerEventViewMore?.eventDoc?.organiserPhone}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {organizerEventViewMore?.eventDoc?.organiserWebsite && (
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Language />
                </Grid>
                <Grid item>
                  <Typography className="text-body1-normal" p={1}>
                    {organizerEventViewMore?.eventDoc?.organiserWebsite}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid>
              {organizerEventViewMore?.eventDoc?.showQr && <QrCode />}
            </Grid>
            <Grid>
              {organizerEventViewMore?.eventDoc?.isActive ? (
                <Chip label="Active" color="success" />
              ) : (
                <Chip label="Inactive" color="error" />
              )}
            </Grid>
          </Grid>
        </Grid>
        {organizerEventViewMore?.ticketBookingDetails && (
          <Grid container>
            <Grid item xs={12} direction="column">
              <Grid item py={2}>
                <Button
                  variant="contained"
                  onClick={() => history("/add-tickets")}
                >
                  Add Tickets
                </Button>
              </Grid>
              <Typography className="text-body1-normal" pt={1} pb={1}>
                Total Booked{" "}
                {organizerEventViewMore?.ticketBookingDetails?.totalBooked}
              </Typography>
            </Grid>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {organizerEventViewMore.eventDoc.ticketTypes.map(
                (item, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Paper>
                      <Grid
                        container
                        p={1.5}
                        sx={{
                          backgroundColor: colors[index % colors.length],
                        }}
                      >
                        <Grid item xs={6} direction="column">
                          <Typography className="text-body1-bold" mb={0.5}>
                            {item.name}
                          </Typography>
                          <Typography className="text-body1-normal">
                            ₹ {item.price}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} direction="column" textAlign="right">
                          <Typography className="text-body1-normal">
                            {
                              organizerEventViewMore?.ticketBookingDetails
                                ?.ticketTypeWiseBooking[item?.code]
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              )}
            </Grid>
            <Grid container>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography className="text-body1-bold">
                    {" "}
                    Tickets Booked
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "20px",
                    }}
                  >
                    <IconButton
                      className="arrow-button"
                      onClick={paginationLeft}
                    >
                      <ChevronLeft />
                    </IconButton>

                    <IconButton
                      className="arrow-button"
                      onClick={paginationRight}
                    >
                      <ChevronRight />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {Tickets.map((item) => (
                  <Grid item>
                    <SingleTickets
                      userDetails={{
                        name: item?.name,
                        mobile: item?.mobile,
                        email: item?.email,
                      }}
                      ticketTypes={item?.ticketTypes}
                      numberOfSeats={item?.numberOfSeats}
                      bookingCode={item?.bookingCode}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  ) : (
    <NoEventsFound />
  );
};
const mapStateToProps = (state) => ({
  organizerEventViewMore: get(state, "event.organizerEventViewMore", []),
});
export default connect(mapStateToProps)(OrganizerEventViewMore);
