import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./FileItem.scss";
import { IconButton, ListItem } from "@mui/material";
import { InsertDriveFile, Delete } from "@mui/icons-material";

const FileItem = ({ file, deleteFile }) => {
  return (
    <>
      <ListItem className="file-item" key={file.name}>
        <InsertDriveFile className="text-blue" />
        <p>{file.name}</p>
        <div className="actions">
          <div className="loading"></div>
          {file.isUploading && (
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin"
              onClick={() => deleteFile(file.name)}
            />
          )}
          {!file.isUploading && (
            <IconButton
              onClick={() => deleteFile(file.name)}
              disableRipple={true}
              sx={{
                width: "0.1rem",
                padding: "0",
                fontSize: "1.2em",
              }}
            >
              <Delete className="text-blue" />
            </IconButton>
          )}
        </div>
      </ListItem>
    </>
  );
};

export default FileItem;
