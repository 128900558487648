import React from "react";
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import { connect } from "react-redux";
import { getInfoEvent } from "../../../redux/actions/eventAction";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ButtonWrapper, SelectWrapper, TextFieldWrapper } from "../../common";
import { EVENT_CODE } from "../../../config/endPoints";
import DeleteIcon from "@mui/icons-material/Delete";

const INITIAL_FORM_STATE = {
  organiserPhone: "",
  name: "",
  organiserEmail: "",
  organiserWebsite: "",
  startDate: "",
  endDate: "",
  description: "",
  eventAddress: "",
  ticketPrice: "",
  eventType: "",
  eventCode: "",
};

const regex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string("Please Enter Your Name").required("Name is Required"),
  organiserPhone: Yup.number("Please enter valid mobile number")
    .integer("Enter your valid mobile number")
    .typeError("Please enter a valid mobile number")
    .required("Required mobile number"),
  organiserEmail: Yup.string()
    .test("email", "Invalid email", (email) => {
      const tester =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!email) return false;
      if (!tester.test(email.trim().toLowerCase())) return false;
      return true;
    })
    .max(45, "Invalid Email")
    .required("Please enter your Email"),
  organiserWebsite: Yup.string("Enter organizer website")
    .matches(regex, "Organizer website is required")
    .required("website is required"),
  description: Yup.string("Please Enter valid Event description").required(
    "Description is required"
  ),
  eventAddress: Yup.string("Enter event address").required(
    "Event Address is Required"
  ),
  ticketPrice: Yup.number("Enter ticket price")
    .integer("Enter valid ticket price")
    .positive("Enter valid ticket price")
    .required("Ticket price is Required"),
  eventCode: Yup.string().required("Please enter event code"),
  ticketCode: Yup.string().required("Ticket code is required"),
  price: Yup.number("Enter ticket price")
    .integer("Enter valid ticket price")
    .positive("Enter valid ticket price")
    .required("Ticket price is Required"),
  ticketName: Yup.string("Enter Ticket Type").required(
    "Ticket name is Required"
  ),
  ticketDesc: Yup.string("Enter Description of Ticket Type").required(
    "Ticket Description is Required"
  ),
  eventType: Yup.string().required("Select event type"),
  maxSeats: Yup.number("Enter maximum number of seats")
    .integer("Enter maximum number of seats")
    .positive("Enter valid maximum number of seats")
    .required("Required Max Seats"),
  // startDate: Yup.date("Date is required").required("Required Start Date"),
  // endDate: Yup.date("Date is required").required("Required End Date"),
});

const Events = ({ onNext, getInfoEvent, updateState }) => {
  const [tickets, settickets] = React.useState([]);

  const [ticketType, setticketType] = React.useState({
    code: "",
    name: "",
    desc: "",
    price: 0,
    maxSeats: 0,
  });

  return (
    <>
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={async (values, { resetForm }) => {
          updateState({
            name: values?.name,
            description: values?.description,
            organiserPhone: values?.organiserPhone,
            organiserEmail: values?.organiserEmail,
            organiserWebsite: values?.organiserWebsite,
            eventType: values?.eventType,
            startDate: new Date(values?.startDate).getTime(),
            endDate: new Date(values?.endDate).getTime(),
            eventAddress: values?.eventAddress,
            ticketPrice: values?.ticketPrice,
            ticketTypes: tickets,
            eventCode: values?.eventCode,
          });
          console.log("values1-->", values);
          onNext();
          resetForm({ values: "" });
        }}
      >
        {({ handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6} direction="column">
                <TextFieldWrapper
                  type="text"
                  name="name"
                  label="Organizer's Name"
                  variant="outlined"
                  placeholder="Organizer Name"
                />
              </Grid>
              <Grid item xs={6} direction="column">
                <TextFieldWrapper
                  type="text"
                  name="description"
                  label="Description"
                  variant="outlined"
                  placeholder="Description"
                />
              </Grid>
              <Grid item xs={6} direction="column">
                <TextFieldWrapper
                  type="text"
                  name="organiserWebsite"
                  label="Website"
                  variant="outlined"
                  placeholder="Organizer Website"
                />
              </Grid>
              <Grid item xs={6} direction="column">
                <TextFieldWrapper
                  label="Mobile number"
                  placeholder="Enter your phone number"
                  variant="outlined"
                  name="organiserPhone"
                  type="text"
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldWrapper
                  type="datetime-local"
                  name="startDate"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldWrapper
                  type="datetime-local"
                  name="endDate"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} direction="column">
                <TextFieldWrapper
                  type="text"
                  name="organiserEmail"
                  label="Email"
                  variant="outlined"
                  placeholder="Organizer Email"
                />
              </Grid>
              <Grid item xs={6} direction="column">
                <TextFieldWrapper
                  type="text"
                  name="eventAddress"
                  label="Event Address"
                  variant="outlined"
                  placeholder="Event Address"
                />
              </Grid>
              <Grid item xs={6} direction="column">
                <TextFieldWrapper
                  type="text"
                  name="ticketPrice"
                  label="Ticket Price"
                  variant="outlined"
                  placeholder="Ticket Price"
                />
              </Grid>
              <Grid item xs={12} direction="column">
                <SelectWrapper
                  label="Event Type"
                  placeholder="Event Type"
                  item={["FCFS"]}
                  name="eventType"
                ></SelectWrapper>
              </Grid>
              <Grid item xs={12} direction="column">
                <Typography mb={1} className="text-body1-bold" py={1}>
                  Ticket Types
                </Typography>
                <div>
                  {tickets.map((val, i) => (
                    <div>
                      <div>
                        <p>
                          <span>Name:</span>
                          {val.name}
                        </p>
                        <p>
                          <span>Price:</span>
                          {val.price}
                        </p>
                        <p>
                          <span>max price:</span>
                          {val.maxSeats}
                        </p>
                      </div>
                      <DeleteIcon
                        onClick={() => {
                          settickets(
                            tickets.filter((ti, index) => i !== index)
                          );
                        }}
                        style={{
                          height: "18px",
                          width: "18px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ))}
                </div>
                <Grid container spacing={1}>
                  <Grid item xs={2} direction="column">
                    <TextFieldWrapper
                      variant="outlined"
                      label="Code"
                      type="text"
                      name="ticketCode"
                      value={ticketType.code}
                      onChange={(e) => {
                        handleChange(e);
                        setticketType({ ...ticketType, code: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} direction="column">
                    <TextFieldWrapper
                      variant="outlined"
                      label="Name"
                      name="ticketName"
                      value={ticketType.name}
                      onChange={(e) => {
                        handleChange(e);
                        setticketType({ ...ticketType, name: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} direction="column">
                    <TextFieldWrapper
                      variant="outlined"
                      label="Description"
                      name="ticketDesc"
                      value={ticketType.desc}
                      onChange={(e) => {
                        handleChange(e);
                        setticketType({ ...ticketType, desc: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} direction="column">
                    <TextFieldWrapper
                      variant="outlined"
                      label="Price"
                      name="price"
                      value={ticketType.price}
                      onChange={(e) => {
                        handleChange(e);
                        setticketType({ ...ticketType, price: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} direction="column">
                    <TextFieldWrapper
                      variant="outlined"
                      label="Maximum Seats"
                      name="maxSeats"
                      value={ticketType.maxSeats}
                      onChange={(e) => {
                        handleChange(e);
                        setticketType({
                          ...ticketType,
                          maxSeats: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} direction="column">
                    <Button
                      onClick={() => {
                        tickets.push(ticketType);
                        setticketType({
                          code: "",
                          name: "",
                          desc: "",
                          price: 0,
                          maxSeats: 0,
                        });
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} direction="column">
                <TextFieldWrapper
                  label="Event Code"
                  placeholder="Event Code"
                  variant="outlined"
                  name="eventCode"
                  type="text"
                />
              </Grid>
              <Grid container xs={12} justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  className="submit-button m-5"
                >
                  <Typography className="text-body1-bold">Next</Typography>
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default connect(null, { getInfoEvent })(Events);
