import React, { useEffect } from "react";
import { connect } from "react-redux";
import { WrapperContainer } from "../../../components/common";
import { OrganizerEventInfo } from "../../../components/event";
import { getInfoOrganizerEvent } from "../../../redux/actions/eventAction";

const OrganizerEventInfoPage = ({ getInfoOrganizerEvent }) => {
  useEffect(() => {
    getInfoOrganizerEvent(0, 10);
  }, []);
  return (
    <WrapperContainer>
      <OrganizerEventInfo />
    </WrapperContainer>
  );
};
export default connect(null, { getInfoOrganizerEvent })(OrganizerEventInfoPage);