import { Container, Grid, Paper, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect } from "react";
import { images } from "../../../assets";
import { connect } from "react-redux";
import { sendOtp } from "../../../redux/actions/userActions";
import { ButtonWrapper, TextFieldWrapper } from "../../common";
import { get } from "lodash";

const INITIAL_FORM_STATE = {
  mobile: "",
};
const FORM_VALIDATION = Yup.object().shape({
  mobile: Yup.number()
    .integer()
    .typeError("Please enter a valid mobile number")
    .required("Required mobile number"),
});

const Login = ({ onNext, sendOtp, error, loading, auth }) => {
  useEffect(() => {
    if (auth?.otpSuccess && auth?.otpId) {
      onNext();
    }
  }, [auth]);
  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper elevation={0} className="p-15">
          <Grid container justifyContent="center" alignItems="center" pb={2}>
            <img src={images.logo} />
          </Grid>
          <Typography
            variant="h5"
            pb={2}
            className="text-h5-bold text-black-500"
          >
            Please fill the details to get yourself verified.
          </Typography>
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values, { resetForm }) => {
              const user = { mobile: values.mobile.trim() };
              await sendOtp(user);
              resetForm({ values: "" });
            }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextFieldWrapper
                  label="Phone Number"
                  placeholder="Enter your phone number"
                  variant="outlined"
                  name="mobile"
                  type="text"
                />
              </Grid>
              <Grid item>
                <ButtonWrapper
                  size="large"
                  variant="contained"
                  color="error"
                  className="button-primary"
                  disabled={loading}
                >
                  <Typography className="text-body1-semibold">
                    {loading ? "..." : "Sign In"}
                  </Typography>
                </ButtonWrapper>
              </Grid>
            </Grid>
          </Formik>
          {error && error?.code === "SEND_OTP_ERROR" && (
            <Typography variant="subtitle1" color="error" component="p">
              {error?.message}
            </Typography>
          )}
        </Paper>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  error: get(state, "auth.error", {}),
  loading: get(state, "auth.loading", false),
  auth: get(state, "auth", {}),
});
export default connect(mapStateToProps, { sendOtp })(Login);
