import {
  SEND_OTP_API,
  UPDATE_ORGANIZER_PROFILE_API,
  VERIFY_OTP_API,
} from '../../config/endPoints'
import instance from '../../utils/instance'
import {
  CLEAR_ERRORS,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  LOGOUT,
  UPDATE_ORGANIZER_PROFILE_REQUEST,
  UPDATE_ORGANIZER_PROFILE_SUCCESS,
  UPDATE_ORGANIZER_PROFILE_FAIL,
} from '../constants'

// Send OTP
export const sendOtp = (userData) => async (dispatch) => {
  try {
    dispatch({ type: SEND_OTP_REQUEST })
    const data = await instance({
      method: 'POST',
      url: SEND_OTP_API,
      data: userData,
    })
      dispatch({
        type: SEND_OTP_SUCCESS,
        payload: data?.data?.otpId,
      })
  } catch (error) {
    dispatch({
      type: SEND_OTP_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'SEND_OTP_ERROR',
      },
    })
  }
}

export const verfiyOtp = (userData) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_OTP_REQUEST })
    const data = await instance({
      method: 'POST',
      url: VERIFY_OTP_API,
      data: userData,
    })
      localStorage.setItem('user', JSON.stringify(data?.data))
      dispatch({
        type: VERIFY_OTP_SUCCESS,
        payload: data.data,
      })
  } catch (error) {
    dispatch({
      type: VERIFY_OTP_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'VERFIY_OTP_ERROR',
      },
    })
  }
}

export const updateOrganizerProfile = (userData) => async (dispatch) => {
  console.log('userData-->',userData)
  try {
    dispatch({ type:  UPDATE_ORGANIZER_PROFILE_REQUEST})
    const data = await instance({
      method: 'POST',
      url: UPDATE_ORGANIZER_PROFILE_API,
      data: userData,
    })
    console.log('data-->',data)
      dispatch({
        type: UPDATE_ORGANIZER_PROFILE_SUCCESS,
        payload: data.data,
      })
  } catch (error) {
    dispatch({
      type: UPDATE_ORGANIZER_PROFILE_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'UPDATE_ORGANIZER_PROFILE_ERROR',
      },
    })
  }
}
// Logout
export const logOut = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  })
}
// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  })
}
