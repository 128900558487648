import {
  CLEAR_ERRORS,
  PAYMENT_FAIL,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
} from "../constants";
const initState = {
  loading: false,
  paymentInfo: {},
  error: {},
};
// Event reducer
export const paymentReducer = (state = initState, action) => {
  switch (action.type) {
    case PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentInfo: action.payload,
      };
    case PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: {},
      };
    default:
      return state;
  }
};
