import React from "react";
import { AddEvents } from "../../../components/event/index";
import { WrapperContainer } from "../../../components/common";

const OrganizerEventAddPage = () => {
  return (
    <WrapperContainer>
      <AddEvents />
    </WrapperContainer>
  );
};

export default OrganizerEventAddPage;