import {
  CLEAR_ERRORS,
  EVENT_INFO_REQUEST,
  EVENT_INFO_SUCCESS,
  EVENT_INFO_FAIL,
  ADD_EVENT_NEW_ITEM,
  REMOVE_EVENT_NEW_ITEM,
  ADD_NEW_ANSWER,
  REMOVE_NEW_ANSWER,
  ADD_CUSTOMERS_DATA,
  REMOVE_CUSTOMERS_DATA,
  ORGANIZER_EVENT_INFO_REQUEST,
  ORGANIZER_EVENT_INFO_SUCCESS,
  ORGANIZER_EVENT_INFO_FAIL,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  ADD_EVENT_REQUEST,
  ADD_EVENT_ID,
  ORGANIZER_EVENT_VIEW_MORE_REQUEST,
  ORGANIZER_EVENT_VIEW_MORE_SUCCESS,
  ORGANIZER_EVENT_VIEW_MORE_FAIL,
} from "../constants";
const initState = {
  loading: false,
  eventInfo: {},
  organizerEventInfo: {},
  organizerEventViewMore: {},
  eventId: "",
  organizerEventId: "",
  error: {},
  data: [],
  eventDetails: {},
};
// Event reducer
export const eventReducer = (state = initState, action) => {
  switch (action.type) {
    case EVENT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case EVENT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        eventInfo: {
          ...action?.payload,
          ticketTypes: action?.payload?.ticketTypes.map((item) => ({
            ...item,
            quantity: 0,
          })),
        },
        error: {},
      };
    case EVENT_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        eventDetails: action.payload,
      };

    case ADD_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ORGANIZER_EVENT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case ORGANIZER_EVENT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        organizerEventInfo: action.payload,
      };
    case ORGANIZER_EVENT_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_CUSTOMERS_DATA:
      const dataInfo = action.payload;
      const isDataExist = state.data.find(
        (i) => i.userDetails.mobile === dataInfo.userDetails.mobile
      );
      if (isDataExist) {
        return {
          ...state,
          data: state.data.map((item) => {
            return item.userDetails.mobile === action.payload.userDetails.mobile
              ? action.payload
              : item;
          }),
        };
      } else {
        return {
          ...state,
          data: [...state.data, action.payload],
        };
      }
    case REMOVE_CUSTOMERS_DATA:
      return {
        ...state,
        data: [],
      };
    case ADD_EVENT_NEW_ITEM:
      return {
        ...state,
        eventInfo: {
          ...state.eventInfo,
          ticketTypes: state.eventInfo.ticketTypes.map((item) =>
            item.code === action.payload.code
              ? {
                  ...item,
                  quantity: action.payload.quantity,
                }
              : item
          ),
        },
      };
    case REMOVE_EVENT_NEW_ITEM:
      return {
        ...state,
        eventInfo: {
          ...state.eventInfo,
          ticketTypes: state.eventInfo.ticketTypes.map((item) => ({
            ...item,
            quantity: 0,
          })),
        },
      };
    case ADD_NEW_ANSWER:
      return {
        ...state,
        eventInfo: {
          ...state.eventInfo,
          newExtraQuestions: action.payload,
        },
      };
    case REMOVE_NEW_ANSWER:
      return {
        ...state,
        eventInfo: {
          ...state.eventInfo,
          newExtraQuestions: "",
        },
      };
    case ADD_EVENT_ID:
      return {
        ...state,
        eventId: action.payload,
      };
    case ORGANIZER_EVENT_VIEW_MORE_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case ORGANIZER_EVENT_VIEW_MORE_SUCCESS:
      return {
        ...state,
        loading: false,
        organizerEventViewMore: action.payload,
      };
    case ORGANIZER_EVENT_VIEW_MORE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: {},
      };
    default:
      return state;
  }
};
