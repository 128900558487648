export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";

export const UPDATE_ORGANIZER_PROFILE_REQUEST = "UPDATE_ORGANIZER_PROFILE_REQUEST";
export const UPDATE_ORGANIZER_PROFILE_SUCCESS = "UPDATE_ORGANIZER_PROFILE_SUCCESS";
export const UPDATE_ORGANIZER_PROFILE_FAIL = "UPDATE_ORGANIZER_PROFILE_FAIL";

export const ORGANIZER_EVENT_INFO_REQUEST = 'ORGANIZER_EVENT_INFO_REQUEST';
export const ORGANIZER_EVENT_INFO_SUCCESS = 'ORGANIZER_EVENT_INFO_SUCCESS';
export const ORGANIZER_EVENT_INFO_FAIL = 'ORGANIZER_EVENT_INFO_FAIL';

//ORGANIZE EVENT VIEW MORE
export const ORGANIZER_EVENT_VIEW_MORE_REQUEST = 'ORGANIZER_EVENT_VIEW_MORE_REQUEST';
export const ORGANIZER_EVENT_VIEW_MORE_SUCCESS = 'ORGANIZER_EVENT_VIEW_MORE_SUCCESS';
export const ORGANIZER_EVENT_VIEW_MORE_FAIL = 'ORGANIZER_EVENT_VIEW_MORE_FAIL';

//ADD EVENT ID
export const ADD_EVENT_ID = "ADD_EVENT_ID";

export const EVENT_INFO_REQUEST = "EVENT_INFO_REQUEST";
export const EVENT_INFO_SUCCESS = "EVENT_INFO_SUCCESS";
export const EVENT_INFO_FAIL = "EVENT_INFO_FAIL";

export const PAYMENT_REQUEST = 'PAYMENT_REQUEST'
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'
export const PAYMENT_FAIL = 'PAYMENT_FAIL'

export const ADD_EVENT_NEW_ITEM = "ADD_EVENT_NEW_ITEM";
export const REMOVE_EVENT_NEW_ITEM = "REMOVE_EVENT_NEW_ITEM";

export const ADD_NEW_ANSWER = "ADD_NEW_ANSWER";
export const REMOVE_NEW_ANSWER = "REMOVE_NEW_ANSWER";

export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const ADD_CUSTOMERS_DATA = "ADD_CUSTOMERS_DATA";
export const REMOVE_CUSTOMERS_DATA = "REMOVE_CUSTOMERS_DATA";

//ADD EVENT
export const ADD_EVENT_REQUEST = "ADD_EVENT_REQUEST";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";

export const EDIT_EVENT = "EDIT_EVENT";
