import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import { getInfoEvent } from "../../../redux/actions/eventAction";
import { Form, Formik } from "formik";
import FileUpload from "./FileUpload/FileUpload";
import FileList from "./FileList/FileList";
import TextFieldWrapper from "../../common/TextFieldWrapper";
import axios from "axios";
import { GET_IMAGE_PRESIGNED_URL_API } from "../../../config/endPoints";
import instance from "../../../utils/instance";

const INITIAL_FORM_STATE = {
  poster: [],
};

const ImageGallery = ({ onNext, getInfoEvent, updateState }) => {
  const [files, setFiles] = React.useState([]);
  const [images, setImages] = React.useState([]);

  const [singleImage, setsingleImage] = React.useState({
    organizerLogoUrl: "",
    sponsorsLogoUrl: "",
    eventMainImageUrl: "",
  });
  console.log("as", singleImage.eventMainImageUrl);
  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };

  const uploadHandler = async (event, field) => {
    const file = event.target.files[0];
    if (!file) return;
    const imageData = {
      fileNames: [`${new Date().valueOf()}_${file.name}`],
      folderType: "EVENTS",
    };
    try {
      const data = await instance({
        method: "POST",
        url: GET_IMAGE_PRESIGNED_URL_API,
        data: imageData,
      });
      if (data.data) {
        await axios
          .put(data.data[0], file)
          .then((response) => {
            if (response.status === 200) {
              file.isUploading = false;
              setsingleImage((prevState) => ({
                ...prevState,
                [field]: data.data[0].split("?")[0],
              }));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        onSubmit={() => {
          updateState({
            poster: images,
            eventMainImageUrl: singleImage.eventMainImageUrl,
            organizerLogoUrl: singleImage.organizerLogoUrl,
            sponsorsLogoUrl: singleImage.sponsorsLogoUrl,
          });
          onNext();
        }}
      >
        <Form>
          <Grid pt={2}>
            <Typography className="text-body1-bold" py={1}>
              Event's Main Image
            </Typography>
            <TextFieldWrapper
              type="file"
              name="eventMainImageUrl"
              onChange={(event) => {
                uploadHandler(event, "eventMainImageUrl");
              }}
            />
          </Grid>
          <Grid pt={2}>
            <Typography className="text-body1-bold" py={1}>
              Organizer's Logo
            </Typography>
            <TextFieldWrapper
              type="file"
              name="organizerLogoUrl"
              onChange={(event) => {
                uploadHandler(event, "organizerLogoUrl");
              }}
            />
          </Grid>
          <Grid pt={2}>
            <Typography className="text-body1-bold" py={1}>
              Sponsor's Logo
            </Typography>
            <TextFieldWrapper
              type="file"
              name="sponsorsLogoUrl"
              onChange={(event) => {
                uploadHandler(event, "sponsorsLogoUrl");
              }}
            />
          </Grid>
          <Grid pt={2}>
            <Typography className="text-body1-bold" py={1}>
              Poster Images
            </Typography>
            <FileUpload
              files={files}
              setFiles={setFiles}
              images={images}
              setImages={setImages}
            />
            <FileList files={files} removeFile={removeFile} />
          </Grid>
          <Grid container xs={12} justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              className="submit-button m-5"
            >
              <Typography className="text-body1-bold">Next</Typography>
            </Button>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

export default connect(null, { getInfoEvent })(ImageGallery);
