import { Box, Toolbar } from "@mui/material";
import React from "react";
import DrawerWrapper from "./Drawer";
import HeaderWrapper from "./Header";

const WrapperContainer = ({ children, window }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const drawerWidth = 240;
  return (
    <Box sx={{ display: "flex" }}>
      <HeaderWrapper
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <DrawerWrapper
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
        container={container}
        mobileOpen={mobileOpen}
      />
      <Box
        component="main"
        sx={{
          height: "100%",
          // maxWidth: "50%",
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default WrapperContainer;
