import { isEmpty } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const SemiProtectedRoute = () => {
  const { user } = useSelector((state) => state.auth);
  if (isEmpty(user) && !user.accessToken) {
    return <Navigate to="/" />;
  } else if (!isEmpty(user) && !user.profileComplete) {
    return <Navigate to="/register" />;
  } else if (!isEmpty(user) && !user.isActive) {
    return <Outlet />;
  }
  return <Navigate to="/event" />;
};
export default SemiProtectedRoute;
