import { PAYMENT_API } from "../../config/endPoints"
import instance from "../../utils/instance"
import { PAYMENT_FAIL, PAYMENT_REQUEST, PAYMENT_SUCCESS } from "../constants"

export const payment = (transactionId) => async (dispatch) => {
    try {
      dispatch({ type: PAYMENT_REQUEST })
      const data = await instance({
        method: 'GET',
        url: PAYMENT_API + transactionId,
      })
        dispatch({
          type: PAYMENT_SUCCESS,
          payload: data.data,
        })
    } catch (error) {
      dispatch({
        type: PAYMENT_FAIL,
        payload: {
          message: error?.response?.data?.message,
          code: 'PAYMENT_ERROR',
        },
      })
    }
}