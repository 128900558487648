import { Button, Grid, Typography } from "@mui/material";
import { get, isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserTable from "../../../components/userTable";
import { NoEventsFound, WrapperContainer } from "../../../components/common";
import { AddTicketDialog, CashfreeDialog } from "../../../components/Dialog";
import {
  NODE_ENV,
  PAYMENT_BULK_VERIFY_API,
  RAZORPAY_KEY,
  RAZORPAY_SCRIPT,
} from "../../../config/endPoints";
import { removeCustomerData } from "../../../redux/actions/eventAction";
import instance from "../../../utils/instance";
import { loadScript } from "../../../utils/loadScript";

const AddTicketsPage = ({
  userTableData,
  removeCustomerData,
  organizerEventViewMore,
}) => {
  const history = useNavigate();
  const initial_state = {
    cashfree: false,
    cashfreeError: "",
  };
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({ ...initial_state });
  const updateState = (data) => setState((state) => ({ ...state, ...data }));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCashfreeClose = () => {
    updateState({
      cashfree: false,
    });
    document.getElementById("drop_in_container").remove();
  };
  const handlePayment = async () => {
    try {
      await instance({
        method: "POST",
        url: PAYMENT_BULK_VERIFY_API,
        data: {
          eventId: organizerEventViewMore?.eventDoc?._id,
          bookings: userTableData,
        },
      }).then(async (res) => {
        if (res.data.paymentGateway === "RZP") {
          const total = userTableData.reduce(
            (acc, item) => acc + item?.originalAmount,
            0
          );
          const resRazorpay = await loadScript(RAZORPAY_SCRIPT);
          if (!resRazorpay) {
            alert("You are offline... Failed to load Razorpay SDK");
            return;
          }
          const options = {
            key: RAZORPAY_KEY,
            amount: total * 100,
            currency: "INR" || "INR",
            name: "FYDO",
            description: "",
            image: "",
            order_id: res.data?.razorpayOrderId,
            handler: () => {
              removeCustomerData();
              history(`/payment-status/${res.data.transactionId}`);
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.on("payment.failed", () => {
            removeCustomerData();
            history(`/payment-status/${res.data.transactionId}`);
          });
          rzp1.open();
        }
        if (res.data.paymentGateway === "CASHFREE") {
          updateState({
            cashfree: true,
          });

          const resCashfree = await loadScript(
            NODE_ENV === "production"
              ? "https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.prod.js"
              : "https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.sandbox.js"
          );
          if (!resCashfree) {
            alert("Cashfree SDK failed to load. Are you online?");
            return;
          }
          try {
            const parent = document.getElementById("drop_in_container");
            const paymentSessionId = res?.data?.paymentSessionId;
            const cashfree = new window.Cashfree({ paymentSessionId });
            const dropConfig = {
              components: [
                "order-details",
                "card",
                "netbanking",
                "app",
                "upi",
                "paylater",
                "credicardemi",
                "cardlessemi",
              ],
              orderId: res?.data?.transactionId,
              orderToken: res?.data?.orderToken,
              onSuccess: (data) => {
                removeCustomerData();
                history(`/payment-status/${res.data.transactionId}`);
              },
              onFailure: (data) => {
                if (
                  data?.order &&
                  data.order.status === "ACTIVE" &&
                  data?.transaction?.txStatus === "FAILED"
                ) {
                  removeCustomerData();
                  history(`/payment-status/${res.data.transactionId}`);
                }
                updateState({
                  cashfreeError: data?.order?.errorText,
                });
              },
              style: {
                backgroundColor: "#ffffff",
                color: "#11385b",
                fontFamily: "Lato",
                fontSize: "14px",
                errorColor: "#ff0000",
                theme: "light",
              },
            };
            cashfree.initialiseDropin(parent, dropConfig);
          } catch (error) {
            console.log("error-->", error);
          }
        }
      });
    } catch (error) {
      console.log("error-->", error);
    }
  };
  console.log("data add ticket", organizerEventViewMore);
  return (
    <>
      <WrapperContainer>
        {!isEmpty(organizerEventViewMore) ? (
          <Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button onClick={handleClickOpen} variant="contained">
                Add Ticket
              </Button>
            </Grid>
            <Grid pt={3}>
              <UserTable />
            </Grid>
            <Grid pt={2}>
              {!isEmpty(userTableData) && (
                <Button variant="contained" onClick={handlePayment}>
                  Pay Now
                </Button>
              )}
            </Grid>
            <AddTicketDialog open={open} handleClose={handleClose} />
            <CashfreeDialog
              open={state.cashfree}
              handleClose={handleCashfreeClose}
            />
          </Grid>
        ) : (
          <NoEventsFound />
        )}
      </WrapperContainer>
    </>
  );
};
const mapStateToProps = (state) => ({
  userTableData: get(state, "event.data", []),
  organizerEventViewMore: get(state, "event.organizerEventViewMore", []),
});

export default connect(mapStateToProps, { removeCustomerData })(AddTicketsPage);
