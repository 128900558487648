import React from 'react'
import Router from './routes'
import { BrowserRouter } from "react-router-dom";
import './main.scss'
import { CssBaseline, StyledEngineProvider } from '@mui/material';

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline/>
      <BrowserRouter>
      <Router/>
      </BrowserRouter>
    </StyledEngineProvider>
  )
}

export default App