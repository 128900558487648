import React from 'react'
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";

const CashfreeDialog = ({open,handleClose}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      sx={{
        "& .css-1fu2e3p-MuiPaper-root-MuiDialog-paper ": {
          borderRadius: "20px",
        },
      }}
    >
       <DialogContent id="drop_in_container" />
        <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
    </Dialog>
  )
}

export default CashfreeDialog