import React from "react";
import { useField } from "formik";
import { TextField } from "@mui/material";

const TextFieldWrapper = ({ name, ...otherProps }) => {
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return <TextField fullWidth {...configTextfield} />;
};

export default TextFieldWrapper;
