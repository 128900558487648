export const NODE_ENV = process.env.REACT_APP_NODE_ENV
export const BASE = process.env.REACT_APP_BASE
export const URL_KEY = process.env.REACT_APP_URL_KEY
export const URL_IV = process.env.REACT_APP_URL_IV
export const SEND_OTP_API = `${BASE}/organiser/send-otp-for-auth`
export const VERIFY_OTP_API = `${BASE}/organiser/validate-otp-for-auth`
export const UPDATE_ORGANIZER_PROFILE_API = `${BASE}/organiser/update-profile`
export const ORGANIZER_EVENT_INFO_DETAILS_API = `${BASE}/organiser/events`
export const ORGANIZER_EVENT_VIEW_MORE_REQUEST_API = `${BASE}/organiser/event-details`
export const EVENT_INFO_DETAILS_API = `${BASE}/event/fetch-event-info?eventCode=`
export const ADD_EVENT_API = `${BASE}/organiser/add-organiser-event`;
export const GET_IMAGE_PRESIGNED_URL_API = `${BASE}/aws-util/generate-presigned-url`;
export const PAYMENT_API = `${BASE}/event-payment/v1/organiser-fetch-payment-status?transactionId=`
export const PAYMENT_BULK_VERIFY_API = `${BASE}/event-payment/v3/process-bulk-payment`
export const RAZORPAY_SCRIPT = process.env.REACT_APP_RAZORPAY_SCRIPT
export const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY
export const EVENT_ID = '62f684281b1645b25d14bf8e'
export const EVENT_CODE = 'test-event'

