import React, { useState } from 'react'
import { Login, Otp } from '../../../components/user';
const steps = {
  1: Login,
  2: Otp,
};
const AuthenticatePage = () => {
  const [step, setStep] = useState(1);
  const Step = steps[step];

  const onNext = () => {
      setStep(step + 1);
  }
  return (
    <Step onNext={onNext} />
  )
}

export default AuthenticatePage