import { get, isEmpty } from 'lodash'
import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { payment } from '../../../redux/actions/paymentAction'

const PaymentStatusPage = ({payment,paymentInfo,loading,error}) => {
    const history = useNavigate()
    const { id } = useParams()
    useEffect(()=> {
        if(id) {
            payment(id)
        }
    },[id])
    const getStatus = async() => {
        if (
          !isEmpty(id) &&
          !isEmpty(paymentInfo) &&
          paymentInfo?.transactionPending
        ) {
          await payment(id)
        }
      }
      useEffect(() => {
        const timeout = setTimeout(() => getStatus(), 4000)
        return () => clearTimeout(timeout)
      }, [id,paymentInfo,getStatus])

    if(loading) {
        return (<Box sx={{ display: 'flex' }}>
            <CircularProgress /> 
        </Box>)
    }
    else if(!isEmpty(paymentInfo) && paymentInfo?.transactionPending) {
        return (
            <Box sx={{ display: 'flex' }}>
            <CircularProgress /> 
        </Box>
        )
    }
    else if(!paymentInfo?.transactionPending && paymentInfo?.transactionSuccessful){
        history('/tickets')
    }
    else {
        return (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress /> 
        </Box>
        )
    }
}
const mapStateToProps = (state) => ({
    loading: get(state, 'pyament.loading', false),
    paymentInfo: get(state, 'payment.paymentInfo', null),
    error: get(state, 'payment.error', {}),
  })
export default connect(mapStateToProps,{payment}) (PaymentStatusPage)