import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  AuthenticatePage,
  HomePage,
  PaymentStatusPage,
  RegisterPage,
  ActivePage,
  OrganizerEventInfoPage,
  OrganizerEventAddPage,
  OrganizerEventViewMorePage,
  PaymentInfoPage,
  AddTicketsPage,
} from "../pages";
import GuestRoute from "./guestRoute";
import ProtectedRoute from "./protectedRoute";
import RegisterProtectedRoute from "./registerProtectedRoute";
import SemiProtectedRoute from "./semiProtectedRoute";

const Router = () => {
  return (
    <Routes>
      <Route element={<GuestRoute />}>
        <Route exact path="/" element={<AuthenticatePage />} />
      </Route>
      <Route element={<RegisterProtectedRoute />}>
        <Route exact path="/register" element={<RegisterPage />} />
      </Route>
      <Route element={<SemiProtectedRoute />}>
        <Route exact path="/active" element={<ActivePage />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        {/* <Route path="/home" element={<HomePage />} /> */}
        <Route path="/event" element={<OrganizerEventInfoPage />} />
        <Route path="/event-info" element={<OrganizerEventViewMorePage />} />
        <Route path="/add-event" element={<OrganizerEventAddPage />} />
        <Route path="/tickets" element={<PaymentInfoPage />} />
        <Route path="/add-tickets" element={<AddTicketsPage />} />
        {/* <Route path="/bookings" element={<BookingPage />} /> */}
        <Route path="/payment-status/:id" element={<PaymentStatusPage />} />
      </Route>
    </Routes>
  );
};

export default Router;
